import React, { useState, useEffect } from 'react';
import { theme } from '../../../themes/default-theme';
import { get } from 'lodash';
import MainNav from './MainNav';
import removeURLParam from '../../../util/removeUrlParam';
import { useMediaQuery } from '@material-ui/core';
import dlPush from '../../../util/dlPush';
import { useRouter } from 'next/router';

const NewMainNav = (props) => {

  // States Start
  const [savedOpen, setSavedOpen] = useState(false);
  const [savedCaravans, setSavedCaravans] = useState(0);
  const [savedCompare, setSavedCompare] = useState(null);
  const [searchOpen, setSearchToggle] = useState(false);
  const [dealerOpen, setDealerToggle] = useState(false);
  const [mainNavToggle, setMainNavToggle] = useState(null);
  const [desktopNavToggle, setDesktopNavToggle] = useState(null);
  const [mobileNavToggle, setMobileNavToggle] = useState(null);
  const [tabContent, setTabContent] = useState(0);
  const [mobileTabContent, setMobileTabContent] = useState(null);
  const [mobileExploreItems, setMobileExploreItems] = useState(null);

  
  // States End

  // Methods Start
  
  const router = useRouter();

  const refactorURL = (url) => {
    return removeURLParam(url, 'domains').replace('-ccnz', '').replace('-ccau', '')
  }

  useEffect(() => {
    getNoOfSaved()
    document.addEventListener("ls:change", getNoOfSaved);
    return () => window.removeEventListener("ls:change", getNoOfSaved);
  }, [])

  const getNoOfSaved = () => {

    const savedCaravans = get(window, 'localStorage.savedCaravans', null);
    if (savedCaravans && savedCaravans.length > 0) {
      const parsedSavedCaravans = JSON.parse(savedCaravans);
      setSavedCaravans(parsedSavedCaravans.length);
    }

    const savedCompareItems = get(window, 'localStorage.compare', null);
    if (savedCompareItems && savedCompareItems.length > 0) {
      const parsedSavedCompare = JSON.parse(savedCompareItems);
      if (parsedSavedCompare && parsedSavedCompare.items) {
        setSavedCompare(parsedSavedCompare.items);
      }
    }

  }

  const handleDesktopNav = (index, item, e, itemIndex, delay = 0) => {
    console.log('handleDesktopNav', index, item, itemIndex);
    e.preventDefault();
    setTimeout(() => {
      setMainNavToggle(index);
      setDesktopNavToggle(null);
      mainNavToggle == index && setMainNavToggle(null);
      setDesktopNavToggle(itemIndex);
      desktopNavToggle == itemIndex && setDesktopNavToggle(null);
      setMobileExploreItems(null);
      setSearchToggle(false);
      setSavedOpen(false);
      setDealerToggle(false);
    }, delay)
  }
  const handleMobileExplore = (index, delay = 0) => {
    setTimeout(() => {
      setMobileExploreItems(index);
      setMainNavToggle(null);
      setDesktopNavToggle(null);
    }, delay)
  }

  const handleNavClose = (delay = 0, e) => {
    e.preventDefault();
    setTimeout(() => {
      setMainNavToggle(null);
      setDesktopNavToggle(null);
      setSearchToggle(false);
      setSavedOpen(false);
      setDealerToggle(false);
    }, delay)
  }

  const navItems = get(props, 'nav', null);

  const rangeItems = props.rangeNav;
  let rangeTitle = '';
  if (rangeItems) {
    rangeTitle = 'Our Range'
  }

  const navArr = (item, itemName) => {
    return (
      Object.keys(item).sort().findIndex((item) => item == itemName)
    )
  }

  const searchTitle = process.env.DEALER_SITE && process.browser ? window.location.host.replace('www.', '') : (props.countryCode.toLowerCase() === 'nz' ? 'jayco.co.nz' : 'jayco.com.au') // ? window.location.host.replace('www.', '') : ;

  const toggleSearch = (e, delay = 0) => {
    e.preventDefault();
    setTimeout(() => {
      setSearchToggle(!searchOpen);
      setMobileExploreItems(null);
      setMainNavToggle(null);
      setDesktopNavToggle(null);
      setDealerToggle(false);
      setSavedOpen(false);
    }, delay)
  }

  const toggleSaved = (e, delay = 0) => {
    e.preventDefault();
    setTimeout(() => {
      setSavedOpen(!savedOpen);
      setMobileExploreItems(null);
      setMainNavToggle(null);
      setDesktopNavToggle(null);
      setDealerToggle(false);
      setSearchToggle(false);
    }, delay)
  }

  const handleSearchSubmit = (val) => {
    setSearchToggle(false);
    window.location.href = "/search?q=" + val
      .split(' ').join('+');
  }

  const handleTabHover = (index, delay = 0) => {
    setTimeout(() => {
      setTabContent(index)
    }, delay)
  }
  const handleTabClick = (index, delay = 0) => {
    setTimeout(() => {
      setMobileTabContent(index)
    }, delay)
    index == mobileTabContent && setMobileTabContent(delay);
  }
  const handleMobileMainToggle = (item, delay = 0) => {
    setTimeout(() => {
      setMobileNavToggle(item);
      mobileNavToggle == item && setMobileNavToggle(null);
    }, delay)
  }

  const defaultDealer = {
    title: "Find a dealer",
    href: '/dealers',
    data: null
  };

  const [dealer, setDealer] = useState({
    title: defaultDealer.title,
    href: defaultDealer.href,
    data: null
  });
  const splitRoute = props.route ? props.route.split('/') : null;
  const section = splitRoute && splitRoute[1] ? splitRoute[1] : null;
  const dealerHref = dealer.href && dealer.href;
  const splitDealer = dealerHref ? dealerHref.split('/') : null;
  const sectionDealer = splitDealer && splitDealer[1] ? splitDealer[1] : null;
  const activeDealer = ((section === sectionDealer) || ((props.route === '/find-a-dealer' || props.route === '/find-a-service-centre') && (dealerHref === '/dealers')) ? 1 : 0)


  const toggleDealer = (delay = 0, e) => {
    setTimeout(() => {
      setDealerToggle(!dealerOpen);
      setMobileExploreItems(null);
      setMainNavToggle(null);
      setDesktopNavToggle(null);
      setSavedOpen(false);
      setSearchToggle(false);
    }, delay)
  }

  const getDealer = (dealer) => {
    if (window && window.localStorage) {
      const dealerJson = localStorage.getItem('dealer');
      const lsDealer = dealerJson ? JSON.parse(dealerJson) : null;
      if (lsDealer && lsDealer.title && lsDealer.title.length > 0 && lsDealer.uri && lsDealer.uri.length > 0) {
        return {
          title: lsDealer.title,
          href: '/dealer/' + lsDealer.uri,
          data: lsDealer.data
        }
      }
    }
    return dealer
  }

  const handleViewDealer = (e) => {
    if (dealer.data) {
      e.preventDefault();
      toggleDealer()
    } else {
      sendEvent(defaultDealer)
    }
  }

  const sendEvent = (link) => {
    if (link && (link.href === '/find-a-dealer' || link.href === '/dealers')) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Find a Dealer',
        'eventCategory': 'Ready to Buy',
        'eventLabel': 'Header CTA'
      })
    }
  }
  // Methods End

  // Effect Start
  // Close menu on route change
  useEffect(() => {
    if (router && router.asPath) {
        setSearchToggle(false);
        setDealerToggle(false);
        setSavedOpen(false);
        setMainNavToggle(null);
        setDesktopNavToggle(null);
        setMobileExploreItems(null);
        setMobileNavToggle(null);
    }
}, [router && router.asPath])

  useEffect(() => {
    const selectedDealer = getDealer(dealer);
    setDealer(selectedDealer);
  }, [])
  // Effect End

  // Media Breakpoint Start
  const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)'
  const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)'

  const desktopView = useMediaQuery(upMd);
  const mobileView = useMediaQuery(downSm);
  // Media Breakpoint End

  return (
    <MainNav
      onSaved={toggleSaved}
      refactorURL={refactorURL}
      {...props}
      assignedDomain={props.assignedDomain}
      countryCode={props.countryCode}
      itemsSaved={savedCaravans}
      compareItems={savedCompare}
      savedOpen={savedOpen}
      savedCaravans={savedCaravans}
      searchOpen={searchOpen}
      dealerOpen={dealerOpen}
      mainNavToggle={mainNavToggle}
      desktopNavToggle={desktopNavToggle}
      mobileNavToggle={mobileNavToggle}
      tabContent={tabContent}
      mobileTabContent={mobileTabContent}
      mobileExploreItems={mobileExploreItems}
      handleDesktopNav={handleDesktopNav}
      handleMobileExplore={handleMobileExplore}
      handleNavClose={handleNavClose}
      handleMobileMainToggle={handleMobileMainToggle}
      navItems={navItems}
      rangeTitle={rangeTitle}
      navArr={navArr}
      searchTitle={searchTitle}
      toggleSearch={toggleSearch}
      handleSearchSubmit={handleSearchSubmit}
      handleTabHover={handleTabHover}
      handleTabClick={handleTabClick}
      dealer={dealer}
      defaultDealer={defaultDealer}
      handleViewDealer={handleViewDealer}
      desktopView={desktopView}
      mobileView={mobileView}
    />
  )
}

NewMainNav.propTypes = {

}

NewMainNav.defaultProps = {
}

export default NewMainNav