import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import {get} from 'lodash';
import {Icon as UiIcon, RespImg} from '..';
import {saveItem, removeItem} from '../../../util/savedCaravans';
import styled from 'styled-components';
import {ReactSVG} from 'react-svg';
import heartFilled from '../../../assets/icons/heartFilled.svg';
import {theme} from '../../../themes/default-theme';
import NextLink from '../../navigation/NextLink';
import Text from '../../typography';
import produce from 'immer';



const Wrapper = styled.div`
`

const Container = styled.div`
`

const Saved = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  ${theme.breakpoints.down('sm')} {
    width: 24px;
    height: 24px;
    position: relative;
  }
  right: 0;
  z-index: 5;
  -webkit-transform: translate3d(0,0,0);

  .heart-icon {
    width: 32px;
    height: 32px;
    background: ${theme.palette.vividBlue};
    ${theme.breakpoints.down('sm')} {
      width: 24px;
      height: 24px;
    }
  }

  svg {
    width: 15px;
    height: 15px;
    pointer-events: none;
    ${theme.breakpoints.down('sm')} {
      width: 10px;
      height: 10px;
    }
  }
`

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  
  a {
    display: block;
    width: 100%;
    cursor: pointer;
  }


  .img {
    width: 100%;
  }

  .img,
  picture {
    padding: 0;
    position: relative;
    z-index: 2;

    ${theme.breakpoints.up('md')} {
      padding: 25px 30px;
    }
  }

  ${theme.breakpoints.up('md')} {

  }
`

const ImageTextContainer = styled.div `
  ${theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
`

const TextContainer = styled.div `
  h6 {
    font-weight: 800;
  }
  ${theme.breakpoints.up('md')} {
    h6 {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 1px;
      text-align: center;
    }
  }
  ${theme.breakpoints.down('sm')} {

    h6 {
      text-align: left;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: normal;
    }
  }
`

const CaravanCardContainer = styled.div`
  margin: 0 auto;
  color: ${theme.palette.vividBlue};
  position: relative;

  ${theme.breakpoints.up('md')} {
    max-width: 335px;
  }

  ${theme.breakpoints.down('sm')} {
    padding: 14px 0;
    border-top: 1px solid ${theme.palette.middleGrey};
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }
`

const SliderWrapper = styled.div `
  width: 100%;
  > div {
    > div {
      ${theme.breakpoints.up('md')} {
          padding-right: 32px;
      }
    }
  }
  
`

const BgImg = styled.div`
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-top: 66.666%;
`


const NewSavedModel = () => {
  const [items, setItems] = useState([]);

  const setSavedItems = () => {
    const savedCaravans = get(window, 'localStorage.savedCaravans', null);
    if (savedCaravans && savedCaravans.length > 0) {
      const parsedSavedCaravans = JSON.parse(savedCaravans);
      setItems(parsedSavedCaravans);
    }
  }

  useEffect(() => {
    setSavedItems()
    document.addEventListener("ls:change", setSavedItems);
    return () => window.removeEventListener("ls:change", setSavedItems);
  },[])

  const handleSave = (e, item, index) => {
    e.preventDefault();
    
    const id = item.id

    if (id) {
      const newState = produce(items, draft => {
        if (item.removed) {
          draft[index] = {
            ...item,
            removed: false
          }
          saveItem({
            ...item,
            removed: false
          })
        } else {
          draft.splice(index, 1);
          // draft[index] = {
          //   ...item,
          //   removed: true
          // }
          removeItem(item)
        }
      });
      setItems(newState);
    }
  }
  
  console.log('save models', items);

  return (
        <Wrapper>
          <Container>
            <SliderWrapper>
              {items.length > 0 ?
              <Grid container spacing={0} direction={'row'} justify="center" align={'left'}>
                {items.map((item, index) => {
                  return (
                    <Grid item xs={12} md={4} key={item.id || index}>
                        <CaravanCardContainer>
                          <Saved>
                            <UiIcon onClick={(e) => handleSave(e, item, index)} skyBlue className={'heart-icon'} key={`${item.id}_icon` || `${index}_icon`}>
                              <ReactSVG src={heartFilled} title="save" key={`${item.id}_svg` || `${index}_svg`}/>
                            </UiIcon>
                          </Saved>
                          <ImageTextContainer>
                            <ImgContainer shadow={item.data.image && item.data.image.sizes ? true : false}>
                              {item.data.image && item.data.image.sizes ?
                              <>
                                {item.data.uri ?
                                  <NextLink href={item.data.uri}>
                                    <a>
                                      <RespImg
                                        {...item.data.image}
                                        type="png"
                                      />
                                    </a>
                                  </NextLink>
                                : 
                                  <RespImg
                                    {...item.data.image}
                                    type="png"
                                  />
                                }
                              </>
                              :
                              <div className="img">
                                <BgImg style={{backgroundImage: `url(${item.data.image})`}}/>
                              </div>
                              }
                            </ImgContainer>
                            <TextContainer>
                              <Text variant="h6" uppercase colour="vividBlue" className="caravan-card-title" align="center">{item.data.title}</Text>
                            </TextContainer>
                          </ImageTextContainer>
                        </CaravanCardContainer>
                    </Grid>
                  )
                })}
              </Grid>
              : 
              <Grid container spacing={0} direction={'row'} justify="center" align={'left'}>
                <Grid item xs={12} md={4}>
                  <Text align="center" variang="body1">No items saved</Text>
                </Grid>
              </Grid>
              }
            </SliderWrapper>
          </Container>
        </Wrapper>
  )
}
  
NewSavedModel.propTypes = {
}
  
NewSavedModel.defaultProps = {
}
  
export default NewSavedModel