import React, { useState, useEffect } from 'react'
import propTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import Text from '../../../components/typography';
import { theme } from '../../../themes/default-theme';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import NavArrowRight from '../../../assets/icons/new_nav_arrow_right.svg';
import dlPush from '../../../util/dlPush';

const Contact = styled.div`
  color: ${theme.palette.greyLightOpacity};
  font-weight: 600;
  ${theme.breakpoints.down('sm')} {
    > div {
      > div {
        &:first-child {
          margin-bottom: 24px;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
          padding-bottom: 30px;
          border-bottom: 1px solid ${theme.palette.middleGrey};
        }
      }
    }
  }
`

const Address = styled.div`
  max-width: 250px;
  > div, ~ a {
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: ${theme.palette.greyLightOpacity};
    font-weight: 600;
  }
  ${theme.breakpoints.up('md')} {
       margin-bottom: 15px;
        > div, ~ a {
            font-size: 15px;
            line-height: 20px;
        }
   }
   ${theme.breakpoints.down('sm')} {
      margin-bottom: 24px;
        > div, ~ a {
            font-size: 14px;
            line-height: 19px;
        }
    }
`

const OpeningHours = styled.div`
> div {
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: ${theme.palette.greyLightOpacity};
    font-weight: 600;
    
  }
  ${theme.breakpoints.up('md')} {
        > div {
            font-size: 15px;
            line-height: 20px;
        }
   }
   ${theme.breakpoints.down('sm')} {
        > div {
            font-size: 14px;
            line-height: 19px;
        }
    }
`

const A = styled.a`
  display: inline-block;
  margin-top: 10px;
  text-decoration: none;
  color: ${theme.palette.greyLightOpacity};
  font-weight: 600;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    margin-top: -1px;
    display: block;
    background-color: #979797;
  }

  &:hover {
    color: ${theme.palette.charcoal};
    &:after {
      background-color: ${theme.palette.charcoal};
    }
  }


  ${theme.breakpoints.down('md')} {
    .svg-container svg {
      height: 10px;
      position: relative;
      top: -2px;
    }
  }

  ${props => props.smallIcon === 1 && `
    .svg-container svg {
      height: 10px;

      ${theme.breakpoints.down('md')} {
        height: 8px;
      }
    }
  `}
`

const Ctas = styled.div`
  width: 100%;

  a {
    ${props => props.smallPadding ? 'padding: 0' : ''}
  }
`
const CtaItem = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: 25px;
  a {
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: ${theme.palette.vividBlue};
    display: flex;
    align-items: center;
    width: max-content;
  }
  ${theme.breakpoints.up('md')} {
    a {
        font-size: 15px;
        line-height: 21px;
    }
  }
  ${theme.breakpoints.down('sm')} {
    a {
        font-size: 15px;
        line-height: 21px;
    }
  }
`

const LinkArrow = styled(ReactSVG)`
  margin-left: 11px;
  > div {
    position: relative;
    top: 1px;
  }
  svg {
    path {
      stroke: ${theme.palette.vividBlue};
    }
  }
`

const ContactDetails = (props) => {

  const {
    title,
    data,
    heading,
    borderColor,
    dealerBtnText,
    ctaWidth,
    stack,
    dealerHref,
    dealer,
    type
  } = props

  const [viewNumber, setViewNumber] = useState(false);
  const [email, setEmail] = useState(null);
  const [serviceQuery, setServiceQuery] = useState(false);

  // Don't render email to prevent bots from picking up
  useEffect(() => {
    setEmail(data.contact.email);
    const serviceQuery = window.location.search.indexOf('service') !== -1 ? true : false;
    setServiceQuery(serviceQuery);
  }, []);

  useEffect(() => {
    if (viewNumber) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'Phone Number Reveal',
        'eventCategory': 'Engage',
        'eventLabel': dealer || `tel:${data.contact.phone}`
      })
    }
  }, [viewNumber]);


  const handleEmailClick = (e, email) => {
    e.preventDefault();
    if (typeof (email) === 'string') {
      location.href = "mailto:" + email;
    } else {
      const link = email.join('@');
      location.href = "mailto:" + link;
    }

  }

  return (
    <Contact>
      <Grid container spacing={0} direction={'row'} justify={'space-between'} align={'left'}>
        <Grid item xs={stack ? 12 : 12} md={4}>
          {data.contact.formattedAddress &&
            <Address>
              <Text variant="body1" colour="charcoal">
                {data.contact.formattedAddress}
              </Text>
            </Address>
          }
          
          <A href={`https://www.google.com/maps/dir/?api=1&destination=${data.location.lat},${data.location.lng}`} target="_blank" smallIcon={1}>
            Get directions
          </A>
          <br />

          <A href={viewNumber ? `tel:${data.contact.phone}` : '#'} onClick={() => setViewNumber(true)}>
            {viewNumber ? data.contact.phone : 'View phone number'}
          </A>
          <br />
          {data.contact.website &&
            <A href={data.contact.website.replace('https', 'http')} target="_blank" smallIcon={1}>
              View dealer website
            </A>
          }
        </Grid>
        {(data.contact.openingHours || data.contact.openingHoursFormatted) &&
          <Grid item xs={stack ? 12 : 12} md={4}>
            <OpeningHours borderColor={borderColor}>
              <Text variant="subtitle1" colour="charcoal">Opening hours</Text>
              <Text variane="body1">{ReactHtmlParser(data.contact.openingHoursFormatted ? data.contact.openingHoursFormatted : data.contact.openingHours)}</Text>
            </OpeningHours>
          </Grid>
        }
        <Grid item xs={stack ? 12 : 12} md={4}>
          <Ctas smallPadding={stack ? false : true}>
            {data.service &&
              <CtaItem width={ctaWidth}>
                <Link href="/book-a-service"><a>Request a service
                  <LinkArrow src={NavArrowRight} /></a></Link>
              </CtaItem>
            }
            {data.sales &&
              <CtaItem width={ctaWidth}>
                <Link
                  href="/book-a-viewing">
                  <a>Request a viewing
                    <LinkArrow src={NavArrowRight} /></a></Link>
              </CtaItem>
            }
            {data.contact.email && <CtaItem width={ctaWidth}>
              <Link href={`#`}><a onClick={(e) => handleEmailClick(e, email)}>Email us
                <LinkArrow src={NavArrowRight} />
              </a></Link></CtaItem>}
            {data.service && serviceQuery ?
              <CtaItem width={ctaWidth}>
                <Link href="/find-a-service-centre"><a>{dealerBtnText}
                  <LinkArrow src={NavArrowRight} />
                </a></Link>
              </CtaItem>
              :
              <CtaItem width={ctaWidth}>
                <Link href={dealerHref}><a>{dealerBtnText}
                  <LinkArrow src={NavArrowRight} />
                </a></Link>
              </CtaItem>
            }
          </Ctas>
        </Grid>
      </Grid>
    </Contact>
  )
}

ContactDetails.propTypes = {
  title: propTypes.string,
  heading: propTypes.string,
  data: propTypes.object,
  borderColor: propTypes.string,
  dealerBtnText: propTypes.string,
  ctaWidth: propTypes.number,
  stack: propTypes.bool,
  dealerHref: propTypes.string,
  type: propTypes.string,
  dealer: propTypes.string
}

ContactDetails.defaultProps = {
  title: 'Contact details',
  heading: null,
  data: {},
  borderColor: theme.palette.lightGrey,
  dealerBtnText: 'Search again',
  ctaWidth: 180,
  stack: true,
  dealerHref: '/find-a-dealer',
  type: null,
  dealer: null
}

export default ContactDetails