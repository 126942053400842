import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import App from 'next/app';
import Theme, { theme } from '../src/themes/default-theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { gql } from "apollo-boost";
import { get } from 'lodash';
import styled from 'styled-components';
import { AllHtmlEntities } from 'html-entities';
import ReactHtmlParser from 'react-html-parser';
import { PageTransition } from 'next-page-transitions';
import Router from 'next/router';
import { Grid } from '@material-ui/core';

import client from '../src/graphql/client';
// import MainNav from '../src/components/navigation/MainNav/MainNav';
import NewMainNav from '../src/components/navigation/NewMainNav';
// import Footer from '../src/components/navigation/FooterNav/MainFooter';
import NewFooterNav from '../src/components/navigation/NewFooterNav';
// import FooterStrip from '../src/components/navigation/FooterNav/Strip';
// import SubNav from '../src/components/navigation/SubNav';
import CtaPanel from '../src/components/navigation/CtaPanel';
// import doesHttpCookieExist from '../src/util/doesHttpCookieExist';
import getCookie from '../src/util/getCookie';
import dlPush from '../src/util/dlPush';

import rangeNavQuery from '../src/graphql/queries/navigation/range-nav';
import headerNavQuery from '../src/graphql/queries/navigation/header-nav';
import footerQuery from '../src/graphql/queries/navigation/footer';
// import footerDataRestructor from '../src/data-restructure/navigation/footer/footer';
import refactorFooterDataV2 from '../src/data-restructure/navigation/footer/footer-v2';
// import refactorMobileNav from '../src/data-restructure/navigation/header/header';
import refactorHeaderNavV2 from '../src/data-restructure/navigation/header/header-v2';
import reciprocalSections from '../src/next-pages/pages.json';
import Disclaimer from '../src/components/ui/Disclaimer';
import NewBreadcrumbs from '../src/components/navigation/NewBreadcrumbs';

const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)'
const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)'

const Container = styled.div`
  ${props => props.margin === 1 && `
    ${upMd} {
      margin-top: 87px;
    }
  `}

  ${props => (props.margin == 1) && (props.dealer == 1) && `
    ${downSm} {
      margin-top: 66px;
    }
  `}

  ${props => (props.margin == 1) && (!props.dealer) && `
    ${downSm} {
      margin-top: 75px;
    }
  `}

  ${props => (props.margin !== 1) && (props.dealer == 1) && `
    ${downSm} {
      margin-top: 66px;
    }
  `}

  ${props => (props.margin !== 1) && (!props.dealer) && `
    ${downSm} {
      margin-top: 75px;
    }
  `}

  // margin-top: 71px;
  // ${upMd} {
  //   margin-top: 87px;
  // }

  ${props => (props.hasSubnav) && (!props.dealer && props.breadcrumbsHeight) && `
    ${downSm} {
      margin-top: ${120 + props.breadcrumbsHeight}px;
    }
  `}
    ${props => (props.hasSubnav) && (!props.dealer) && `
    ${upMd} {
      margin-top: 140px;
    }
  `}
  
  ${props => (props.hasSubnav) && (props.dealer == 1 && props.breadcrumbsHeight) && `
    ${downSm} {
      margin-top: ${112 + props.breadcrumbsHeight}px;
    }
  `}
    ${props => (props.hasSubnav) && (props.dealer == 1) && `
      ${upMd} {
        margin-top: 140px;
      }
  `}
    ${props => props.BreadcrumbsMargin == 1 && `
          ${upMd} {
            [class^="NewBreadcrumbs__Container"] {
              margin-top: 95px;
          }
        }
         ${downSm} {
            [class^="NewBreadcrumbs__Container"] {
              margin-top: 10px;
          }
        }
      `}

`

const SubNavWrapper = styled.div`
  padding: 0 35px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1470px;
  z-index: 1;
  position: fixed;
  z-index: 3;

  ${theme.props.desktopNavUp} {
    top: 75px;
  }

  ${downSm} {
    top: 80px;
  }
`

const SubNavContainer = styled.div`
  position: relative;
  max-width: 1400px;
`

const CtaPanelContainer = styled.div`
  margin: 50px 0;
  padding: 0 ${theme.containers.main.gutter};

  ${props => props.colourTheme && props.colourTheme === 'black' && `
    margin: 0;
    padding: 50px ${theme.containers.main.gutter};
    background-color: ${theme.palette.shadeBlack};
  `} 
  
  ${upMd} {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
`

const Wrapper = styled.div`
  ${props => props.padding === 1 && `
    padding-top: 40px;
    ${theme.props.desktopNavUp} {
      padding-top: 0;
    }
  `}
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Top = styled.div`
  flex: 1 0 auto;
  ${props => props.greyBg && `
    background-color: ${theme.palette.lightGrey};
  `}
`
const Bottom = styled.div`
  flex-shrink: 0;
  ${theme.breakpoints.up('md')} {
    z-index: 2;
  }
  ${props => props.mobilePaddingBottom && `
    ${theme.breakpoints.down('sm')} {
      padding-bottom: 52px;
    }
  `}
`

const DisclaimerWrapper = styled.div`
  padding: 30px 0;
  background-color: ${theme.palette.lightGrey};
`

const DisclaimerBox = styled.div`
  padding-left: 16px;
  padding-right: 16px;
`

const DisclaimerContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`

// const replaceAll = (str, find, replace) => {
//   return str.replace(new RegExp(find, 'g'), replace);
// }

const MyApp = (props) => {

  const { Component, pageProps, router, navProps, footerProps, footerStripProps, countryProps } = props;

  const appProps = get(props, 'pageProps.stringData', null) ? {
    ...props,
    pageProps: JSON.parse(props.pageProps.stringData)
  } : props;

  const colourTheme = get(appProps, 'pageProps.theme', null);

  console.log('_app props', colourTheme, appProps);

  const [route, setRoute] = useState(router.asPath);

  const [breadcrumbsHeight, setBreadcrumbsHeight] = useState(0);


  if (process.browser) {
    // console.log(props);
    // console.log('_app props', props);
    console.log('countryProps.assignedDomain', countryProps.assignedDomain);
    // console.log('process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN', process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN);
  }

  const subNav = get(appProps, 'pageProps.subNav', null);
  const subNavActive = get(router, 'asPath', '');

  const marginTop = get(appProps, 'pageProps.marginTop', null);

  let hasBanner = marginTop !== null ? !marginTop : false;

  // console.log('appProps', appProps);

  const cbData = get(appProps, 'pageProps.cbData', null);

  // if (cbData && (get(cbData, '[0].type', '') === 'bannerSlider')) {
  //   hasBanner = true
  // } else if (cbData && (get(cbData, '[0].type', '') === 'bannerSingle')) {
  //   hasBanner = true
  // } else if (get(pageProps, 'pageData[0].type', '') === 'bannerSingle') {
  //   hasBanner = true
  // } else if (get(appProps, 'pageProps.banner', null)) {
  //   hasBanner = true
  // } else if (get(appProps, 'pageProps.heroBanner', null)) {
  //   hasBanner = true
  // } else if (get(pageProps, 'pageData[0].type', '') === 'bannerSlider') {
  //   hasBanner = true
  // }
  // Add others here

  const activeRoute = () => {
    setRoute(router.asPath)
  }

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    if (process.env.PREVIEW_MODE === 'true' && process.env.NODE_ENV !== 'development') {
      const cookie = getCookie('__staticly_next_preview');
      const buildID = process.env.BUILD_ID;
      if (!cookie || cookie !== buildID.toString()) {
        Router.push(`/api/preview?redirect=${window.location.href}&id=${buildID}`)
      }
    }

    if (!process.env.DEALER_SITE) {
      // Dealer sites do a full page load
      const handleRouteChange = (url) => {
        activeRoute()
        dlPush({
          event: 'pageview',
          appHost: window.location.host,
          appPagepath: url,
          appPagetitle: document.title,
          appUrl: window.location.origin + (window.location.pathname !== '/' ? window.location.pathname : '')
        });
      }

      Router.events.on('routeChangeComplete', handleRouteChange);

      return () => {
        Router.events.off('routeChangeComplete', handleRouteChange);
      };
    }

    if (window.location.hostname === 'www.jaycocanberra.com.au' || window.location.hostname === 'jaycocanberra.com.au') {
      var scriptElem = document.createElement('script');
      scriptElem.src = 'https://integrator.swipetospin.com';
      document.getElementsByTagName('head')[0].appendChild(scriptElem);
    }

  }, []);

  const handlePanelClick = (item) => {
    if (item.href === '/find-a-dealer') {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Find a Dealer',
        'eventCategory': 'Ready to Buy',
        'eventLabel': 'Widget CTA'
      })
    }
    if (item.href === '/brochure-request') {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Request Brochure',
        'eventCategory': 'Research',
        'eventLabel': 'Widget CTA'
      })
    }
    if (item.href === '/enews-registration') {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Newsletter Sign-up',
        'eventCategory': 'Research',
        'eventLabel': 'Widget CTA'
      })
    }
  }

  const dealerCanonicalDomain = (props.router.route.indexOf('staticly-template') !== -1 || props.router.asPath === '/' || props.router.asPath.split('?')[0] === '/stock') ? (process.browser && window.staticly ? `https://www.${window.staticly.dealerData.website}` : 'https://www.{{data.template.dealer.website}}') : 'https://www.jayco.com.au'
  // console.log('pro', props, 'dealerCanonicalDomain', dealerCanonicalDomain);
  const domain = process.env.DEALER_SITE ? (process.browser ? `https://www.${window.staticly.dealerData.website}` : 'https://www.{{data.template.dealer.website}}') : `https://www.${countryProps.assignedDomain}`;
  // Add more / update terms here
  const countryName = countryProps.countryCode === 'AU' ? 'Australia' : 'New Zealand';
  const countryPlural = countryProps.countryCode === 'AU' ? "Australia's" : "New Zealand's";

  /**
   * Meta tags
   */
  const htmlEntities = new AllHtmlEntities(); // use entities.decode() on meta titles
  const stripUri = router.asPath.split(/[?#]/)[0]; // remove query string and hash for canonical and hreflang
  const canonicalDomain = process.env.DEALER_SITE ? dealerCanonicalDomain : domain;
  // get canonical from CMS first, if empty assign current URL to canonical
  let cmsCanonical = get(appProps, 'pageProps.meta.seo.canonical', undefined);
  if (cmsCanonical) {
    cmsCanonical = cmsCanonical.split(/[?#]/)[0]; // remove query string and hash for canonical and hreflang
    // search replace local preview domain
    if (cmsCanonical.includes('staticlylocal.com')) {
      let uri = cmsCanonical.split("staticlylocal.com")[1];
      cmsCanonical = `${canonicalDomain}${uri}`;
    }
    // search replace other preview domains
    if (cmsCanonical.includes('staticlyproject.com')) {
      let uri = cmsCanonical.split("staticlyproject.com")[1];
      cmsCanonical = `${canonicalDomain}${uri}`;
    }
  }

  const canonicalTag = cmsCanonical ? cmsCanonical : `${canonicalDomain}${stripUri}`;
  const stockCanonical = cmsCanonical ? cmsCanonical : `${dealerCanonicalDomain}${router.asPath}`;

  // console.log('canonicalDomain', canonicalDomain);
  // console.log('stockCanonical', stockCanonical);

  const canonical = stripUri === '/stock' ? stockCanonical : canonicalTag;

  const rawDescription = get(appProps, 'pageProps.meta.seo.metaDesc', undefined);
  const metaTitle = get(appProps, 'pageProps.meta.seo.title', null);
  const title = metaTitle ? htmlEntities.decode(`${metaTitle} | Jayco ${countryName}`) : `Jayco ${countryName} | ${countryProps.assignedDomain}`;
  const dealerTitle = metaTitle ? htmlEntities.decode(`${metaTitle} | ${process.browser && window.staticly ? window.staticly.dealerData.name : '{{data.template.dealer.name}}'}`) : `${process.browser && window.staticly ? window.staticly.dealerData.name : '{{data.template.dealer.name}}'}`;
  const noIndex = get(pageProps, 'meta.seo.metaRobotsNoindex', 'index').toLowerCase() === 'noindex' ? true : false;

  const description = rawDescription ? rawDescription.replace(/\[country\]/g, countryName).replace(/\[country_plural\]/g, countryPlural) : undefined;
  const cmsSeoTitle = get(appProps, 'pageProps.meta.seo.title', undefined);
  const seoTitle = cmsSeoTitle ? htmlEntities.decode(cmsSeoTitle) : (process.env.DEALER_SITE ? dealerTitle : title);
  // facebook
  const cmsOgTitle = get(appProps, 'pageProps.meta.seo.opengraphTitle', undefined);
  const ogTitle = cmsOgTitle ? cmsOgTitle : seoTitle;
  const cmsOgDescription = get(appProps, 'pageProps.meta.seo.opengraphDescription', undefined);
  const ogDescription = cmsOgDescription ? cmsOgDescription : description;
  const ogImage = get(appProps, 'pageProps.meta.seo.opengraphImage.sourceUrl', undefined);
  const ogSiteName = countryProps.countryCode === 'AU' ? 'Jayco Australia' : 'Jayco New Zealand';
  // twitter
  const cmsTwitterTitle = get(appProps, 'pageProps.meta.seo.twitterTitle', undefined);
  const twitterTitle = cmsTwitterTitle ? cmsTwitterTitle : seoTitle;
  const cmsTwitterDescription = get(appProps, 'pageProps.meta.seo.twitterDescription', undefined);
  const twitterDescription = cmsTwitterDescription ? cmsTwitterDescription : description;
  const twitterImage = get(appProps, 'pageProps.meta.seo.twitterImage.sourceUrl', undefined);
  const twitterSite = countryProps.countryCode === 'AU' ? '@JaycoAU' : '@JaycoNewZealand';
  // hreflang  
  const isRecipricalDomain = reciprocalSections && stripUri ? reciprocalSections.indexOf(`/${stripUri.split('/')[1]}`) !== -1 : false
  const hreflang = get(appProps, 'pageProps.meta.showHreflang', isRecipricalDomain);
  const hreflangUrl = countryProps.countryCode === 'AU' ? `https://www.jayco.com.au${stripUri}` : `https://www.jayco.co.nz${stripUri}`;
  const otherHreflangUrl = countryProps.countryCode === 'AU' ? `https://www.jayco.co.nz${stripUri}` : `https://www.jayco.com.au${stripUri}`;
  const otherCountryCode = countryProps.countryCode === 'AU' ? 'NZ' : 'AU'

  // Add Google Schema Mark up to logo for AU and NZ sites
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": `Jayco ${countryName}`,
    "url": `${domain}${router.asPath}`,
    "logo": `${domain}/images/logo.svg`
  }

  if (router.route === '/update-browser') {
    return <Component router={router} key={router.route} countryProps={countryProps} footerProps={footerProps} />
  }

  const ctaPanelData = [
    {
      id: '1',
      title: "Request a brochure",
      href: "/brochure-request",
      icon: "brochure"
    },
    {
      id: '2',
      title: "eNews sign up",
      href: "/enews-registration",
      icon: "enews"
    }
  ]

  const customDealerCtaPanelData = (process.browser && window.staticly && window.staticly.dealerData.eCommerce && window.staticly.dealerData.eCommerce.url) ? [
    {
      id: '3',
      title: window.staticly.dealerData.eCommerce.title,
      href: window.staticly.dealerData.eCommerce.url,
      target: '_blank',
      icon: "cart"
    }
  ] : []

  const dealerCtaPanelData = [
    ...customDealerCtaPanelData,
    {
      id: '4',
      title: "Contact us",
      href: "/contact-us",
      icon: "phone"
    },
    ...ctaPanelData
  ]

  const mainCtaPanelData = [
    {
      id: '5',
      title: "Find a dealer",
      href: "/find-a-dealer",
      icon: "dealer"
    },
    ...ctaPanelData,
  ]

  const mainSite = !process.env.DEALER_SITE

  const greyBg = get(appProps, 'pageProps.greyBg', false);

  const breadcrumbsPosition = props?.pageProps?.pageData?.length > 0 && props.pageProps.pageData[0].type && (props.pageProps.pageData[0].type == 'heroBanner_v2_2023' || props.pageProps.pageData[0].type == 'bannerSlider' || props.pageProps.pageData[0].type == 'bannerSingle') ? 'absolute'
    : (props?.pageProps?.heroBanner || props?.pageProps?.hero || props.pageProps?.heroBannerCarousel?.length > 0 || router?.asPath == '/for-sale' || (props.pageProps?.contentBuilder?.length > 0 && props.pageProps.contentBuilder[0]?.type == 'heroBanner_v2_2023')) ? 'absolute'
      : 'relative';

  const getBreadcrumbsHeight = (breadcrumbsHeight) => {
    if (breadcrumbsHeight) {
      setBreadcrumbsHeight(breadcrumbsHeight)
      console.log('breadcrumbsHeight', breadcrumbsHeight)
    }
  }


  return (
    <React.Fragment>
      <Head>

        {mainSite && props.router.route === '/' &&
          ReactHtmlParser(`<meta name="facebook-domain-verification" content="gbh2vy90n242u2hicaeoxaiwric9aa" />`)
        }

        {mainSite || process.browser ? <>
          {noIndex && <meta name="robots" content="noindex" />}
          {/* SEO: App description for search-engine optimization */}

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.description ?
            <meta name="Description" content={window.staticly.dealerData.meta.description} key="Description" />
            :
            description ? <meta name="Description" content={description} key="Description" /> : null
          }

          {/* Bonus: Have beautiful preview tiles when users share your website on social media  */}
          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.ogTitle ?
            <meta property="og:title" content={window.staticly.dealerData.meta.ogTitle} key="og:title" />
            :
            ogTitle ? <meta property="og:title" content={ogTitle} key="og:title" /> : null
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.ogDescription ?
            <meta property="og:description" content={window.staticly.dealerData.meta.ogDescription} key="og:description" />
            :
            ogDescription ? <meta property="og:description" content={ogDescription} key="og:description" /> : null
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.ogImage ?
            <meta property="og:image" content={window.staticly.dealerData.meta.ogImage} key="og:image" />
            :
            ogImage ? <meta property="og:image" content={ogImage} key="og:image" /> : null
          }

          <meta property="og:type" content="website" />

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.ogUrl ?
            <meta property="og:url" content={window.staticly.dealerData.meta.ogUrl} key="og:url" />
            :
            <meta property="og:url" content={`${domain}${router.asPath}`} key="og:url" />
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.ogSiteName ?
            <meta property="og:site_name" content={window.staticly.dealerData.meta.ogSiteName} key="og:site_name" />
            :
            <meta property="og:site_name" content={ogSiteName} key="og:site_name" />
          }

          <meta name="twitter:card" content="summary_large_image" key="twitter:card" />

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.twitterSite ?
            <meta name="twitter:site" content={window.staticly.dealerData.meta.twitterSite} key="twitter:site" />
            :
            <meta name="twitter:site" content={twitterSite} key="twitter:site" />
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.twitterTitle ?
            <meta name="twitter:title" content={window.staticly.dealerData.meta.twitterTitle} key="twitter:title" />
            :
            <meta name="twitter:title" content={twitterTitle} key="twitter:title" />
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.twitterDescription ?
            <meta name="twitter:description" content={window.staticly.dealerData.meta.twitterDescription} key="twitter:description" />
            :
            twitterDescription ? <meta name="twitter:description" content={twitterDescription} key="twitter:description" /> : null
          }

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.twitterImage ?
            <meta property="twitter:image" content={window.staticly.dealerData.meta.twitterImage} key="twitter:image" />
            :
            twitterImage ? <meta property="twitter:image" content={twitterImage} key="twitter:image" /> : null
          }

          {/* hreflang */}

          {process.browser && process.env.DEALER_SITE && window.staticly.dealerData.meta.title ?
            <title key={"title"}>{`${window.staticly.dealerData.meta.title}${window.staticly.dealerData.name ? ` | ${window.staticly.dealerData.name}` : null}`}</title>
            :
            <title key={"title"}>{ReactHtmlParser(mainSite ? title : dealerTitle)}</title>
          }
          {canonical && !noIndex && <link rel="canonical" href={canonical} key="canonical" />}

        </>
          :
          !process.browser &&
          <>
            <meta name="Description" content={`{{# if data.template.meta.seo.metaDesc}}{{{data.template.meta.seo.metaDesc}}}{{else}}${description ? description : ''}{{/if}}`} key="Description" />
            <meta name="og:title" content={`{{# if data.template.meta.seo.opengraphTitle}}{{{data.template.meta.seo.opengraphTitle}}}{{else}}${ogTitle ? ogTitle : ''}{{/if}}`} key="og:title" />
            <meta property="og:description" content={`{{# if data.template.meta.seo.opengraphDescription}}{{{data.template.meta.seo.opengraphDescription}}}{{else}}${ogDescription ? ogDescription : ''}{{/if}}`} key="og:description" />
            <meta property="og:image" content={`{{# if data.template.meta.seo.opengraphImage}}{{{data.template.meta.seo.opengraphImage}}}{{else}}${ogImage ? ogImage : ''}{{/if}}`} key="og:image" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`{{# if data.template.uri}}${domain}{{data.template.uri}}{{else}}${domain}${router.asPath}{{/if}}`} key="og:url" />
            <meta property="og:site_name" content={`{{# if data.template.dealer.name}}{{{data.template.dealer.name}}}{{else}}${ogSiteName ? ogSiteName : ''}{{/if}}`} key="og:site_name" />
            <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
            <meta name="twitter:site" content={`{{# if data.template.meta.seo.twitterSite}}{{data.template.meta.seo.twitterSite}}{{else}}${twitterSite ? twitterSite : ''}{{/if}}`} key="twitter:site" />
            <meta name="twitter:title" content={`{{# if data.template.meta.seo.twitterTitle}}{{data.template.meta.seo.twitterSite}}{{else}}${twitterTitle ? twitterTitle : ''}{{/if}}`} key="twitter:title" />
            <meta name="twitter:description" content={`{{# if data.template.meta.seo.twitterSite}}{{data.template.meta.seo.twitterSite}}{{else}}${twitterDescription ? twitterDescription : ''}{{/if}}`} key="twitter:description" />
            <meta property="twitter:image" content={`{{# if data.template.meta.seo.twitterImage}}{{data.template.meta.seo.twitterImage}}{{else}}${twitterImage ? twitterImage : ''}{{/if}}`} key="twitter:image" />

            <title key={"title"}>
              {ReactHtmlParser(`
                {{# if data.template.meta.seo.title}}
                  {{{data.template.meta.seo.title}}}{{# if data.template.dealer.name}} | {{data.template.dealer.name}}{{/if}}
                {{else if data.template.meta.title}}
                  {{{data.template.meta.title}}}{{# if data.template.dealer.name}} | {{data.template.dealer.name}}{{/if}}
                {{else}}
                  ${dealerTitle}
                {{/if}}
              `)}
            </title>

            {canonical && !noIndex && <link rel="canonical" href={canonical} key="canonical" />}

            {/* <>{"{{# if data.template.meta.seo.metaDesc}}"}</>
              <>{ReactHtmlParser('<meta name="Description" content="{{{data.template.meta.seo.metaDesc}}}" key="Description"/>')}</>
              <>{"{{else}}"}</>
              {description ? <meta name="Description" content={description} key="Description" /> : ''}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.opengraphTitle}}"}</>
               <>{ReactHtmlParser(`<meta property="og:title" content="{{{data.template.meta.seo.opengraphTitle}}}" key="og:title" />`)}</>
              <>{"{{else}}"}</>
              {ogTitle ? <meta property="og:title" content={ogTitle} key="og:title" /> : ''}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.opengraphDescription}}"}</>
              <>{ReactHtmlParser(`<meta property="og:description" content="{{{data.template.meta.seo.opengraphDescription}}}" key="og:description"/>`)}</>
              <>{"{{else}}"}</>
              {ogDescription ? <meta property="og:description" content={ogDescription} key="og:description"/> : ''}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.opengraphImage}}"}</>
              <>{ReactHtmlParser(`<meta property="og:image" content="{{{data.template.meta.seo.opengraphImage}}}" key="og:image"/>`)}</>
              <>{"{{else}}"}</>
              {ogImage ? <meta property="og:image" content={ogImage} key="og:image"/> : ''} 
            <>{"{{/if}}"}</>
            <meta property="og:type" content="website" />
            <>{"{{# if data.template.uri}}"}</>
              <>{ReactHtmlParser(`<meta property="og:url" content="${domain}{{data.template.uri}}" key="og:url"/>`)}</>
              <>{"{{else}}"}</>
              <meta property="og:url" content={`${domain}${router.asPath}`} key="og:url"/>
            <>{"{{/if}}"}</> 
            <>{"{{# if data.template.dealer.name}}"}</>
              <>{ReactHtmlParser(`<meta property="og:site_name" content="{{{data.template.dealer.name}}}" key="og:site_name" />`)}</>
              <>{"{{else}}"}</>
              {ogSiteName ? <meta property="og:site_name" content={ogSiteName} key="og:site_name" /> : ''}
            <>{"{{/if}}"}</>
            <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
            <>{"{{# if data.template.meta.seo.twitterSite}}"}</>
              <>{ReactHtmlParser(`<meta name="twitter:site" content="{{data.template.meta.seo.twitterSite}}"  key="twitter:site"/>`)}</>
              <>{"{{else}}"}</>
              {twitterSite ? <meta name="twitter:site" content={twitterSite}  key="twitter:site"/> : ''}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.twitterTitle}}"}</>
              <>{ReactHtmlParser(`<meta name="twitter:title" content="{{{data.template.meta.seo.twitterTitle}}}" key="twitter:title"/>`)}</>
              <>{"{{else}}"}</>
              {twitterTitle ? <meta name="twitter:title" content={twitterTitle} key="twitter:title"/> : '' }
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.twitterDescription}}"}</>
              <>{ReactHtmlParser(`<meta name="twitter:description" content="{{{data.template.meta.seo.twitterDescription}}}" key="twitter:description"/>`)}</>
              <>{"{{else}}"}</>
              {twitterDescription ? <meta name="twitter:description" content={twitterDescription} key="twitter:description"/> : null}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.twitterImage}}"}</>
              <>{ReactHtmlParser(`<meta property="twitter:image" content="{{data.template.meta.seo.twitterImage}}" key="twitter:image" />`)}</>
              <>{"{{else}}"}</>
              {twitterImage ? <meta property="twitter:image" content={twitterImage} key="twitter:image" /> : null}
            <>{"{{/if}}"}</>
            <>{"{{# if data.template.meta.seo.title}}"}</>
              <>{ReactHtmlParser('<title>{{{data.template.meta.seo.title}}}{{# if data.template.dealer.name}} | {{data.template.dealer.name}}{{/if}}</title>')}</>
              <>{"{{else if data.template.meta.title}}"}</>
              <>{ReactHtmlParser('<title>{{{data.template.meta.title}}}{{# if data.template.dealer.name}} | {{data.template.dealer.name}}{{/if}}</title>')}</>
              <>{"{{else}}"}</>
              {dealerTitle ? <title key={"title"}>{ReactHtmlParser(dealerTitle)}</title> : '' }
            <>{"{{/if}}"}</> */}

          </>
          // : <> </>
        }

        {!process.env.DEALER_SITE && hreflang ?
          <>
            <link rel="alternate" hrefLang={`en-${countryProps.countryCode}`} href={`${hreflangUrl}`} />
            <link rel="alternate" hrefLang="en" href={`https://www.jayco.com.au${stripUri}`} />
            <link rel="alternate" hrefLang={`en-${otherCountryCode}`} href={`${otherHreflangUrl}`} />
            <link rel="alternate" hrefLang="x-default" href={`https://www.jayco.com.au${stripUri}`} />
          </>
          : <></>
        }

        <meta name="viewport" content="width=device-width, initial-scale=1.0" id="viewportMeta" />
        {process.env.DEALER_SITE !== true && router.route === '/' &&
          <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationSchema) }} />
        }

      </Head>

      <Theme>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />

        <Wrapper padding={process.env.DEALER_SITE ? 0 : 0}>
          <Top greyBg={greyBg}>
            {/*
          <MainNav 
            data={navProps}
            assignedDomain={countryProps.assignedDomain}
            countryCode={countryProps.countryCode}
            hasSubnav={subNav ? true : false}
            route={route}
          />
          */}

            <NewMainNav
              {...navProps}
              assignedDomain={countryProps.assignedDomain}
              countryCode={countryProps.countryCode}
              hasSubnav={subNav ? true : false}
              route={route}
            />

            {/* {subNav &&
            <SubNavWrapper>
              <SubNavContainer>
                <SubNav 
                  active={subNavActive}
                  data={subNav}
                />
              </SubNavContainer>
            </SubNavWrapper>
          } */}

            <Container margin={hasBanner ? 0 : 1} hasSubnav={subNav ? true : false} dealer={process.env.DEALER_SITE !== true ? 0 : 1} breadcrumbsHeight={breadcrumbsHeight} BreadcrumbsMargin={(router?.asPath == '/for-sale' || router?.asPath == '/compare' || router?.asPath.indexOf('/compare?') > -1) ? 1 : 0}>
              {props?.pageProps?.breadcrumbs && router?.asPath.indexOf('for-sale/') <= 0 &&
                <NewBreadcrumbs
                  list={get(props, 'pageProps.breadcrumbs.list', null)} showBreadcrumbs={props.router.asPath !== '/' ? true : false} breadcrumbsPosition={breadcrumbsPosition} hasSubnav={subNav ? true : false} margin={hasBanner ? false : true} setBreadcrumbsHeight={getBreadcrumbsHeight}
                />
              }
              <PageTransition timeout={300} classNames={((router?.asPath.indexOf('build-price') !== -1 && router?.asPath.indexOf('variant') !== -1) || (router?.asPath.indexOf('build-price') !== -1 && router?.asPath.indexOf('floorplan') !== -1)) ? "build-price-page-transition" : "page-transition"}>
                <Component {...pageProps} router={router} key={router.asPath} countryProps={countryProps} breadcrumbsHeight={breadcrumbsHeight} />
              </PageTransition>
              <style jsx global>{`
                .page-transition-enter {
                  opacity: 0;
                }
                .page-transition-enter-active {
                  opacity: 1;
                  transition: opacity 300ms;
                }
                .page-transition-exit {
                  opacity: 1;
                }
                .page-transition-exit-active {
                  opacity: 0;
                  transition: opacity 300ms;
                }
              `}</style>
            </Container>
            {pageProps.footerDisclaimer &&
              <DisclaimerWrapper>
                <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
                  <Grid item xs={12}>
                    <DisclaimerBox>
                      <DisclaimerContainer>
                        <Disclaimer>
                          {ReactHtmlParser(pageProps.footerDisclaimer)}
                        </Disclaimer>
                      </DisclaimerContainer>
                    </DisclaimerBox>
                  </Grid>
                </Grid>
              </DisclaimerWrapper>
            }
            {(router?.asPath.indexOf('build-price') <= 0) &&
              <CtaPanelContainer colourTheme={colourTheme}>
                {(mainSite || (!mainSite && process.browser)) &&
                  <CtaPanel
                    onItemClick={(item) => handlePanelClick(item)}
                    panelData={mainSite ? mainCtaPanelData : dealerCtaPanelData}
                  />
                }
              </CtaPanelContainer>
            }
          </Top>
          <Bottom mobilePaddingBottom={pageProps.mobilePaddingBottom}>
            <div id="main-footer">
              {footerProps &&
                <NewFooterNav
                  {...footerProps}
                  assignedDomain={countryProps.assignedDomain}
                  countryCode={countryProps.countryCode}
                />
              }
            </div>
          </Bottom>
        </Wrapper>

      </Theme>
    </React.Fragment>
  );
}

MyApp.getInitialProps = async ctx => {
  if (process.browser) {
    return
  }
  const appProps = await App.getInitialProps(ctx);

  const footerPrimaryQuery = process.env.DEALER_SITE ? 'Dealer Footer' : 'Footer'

  const GET_FOOTER_QUERY = `
    footerPrimary: ${footerQuery(footerPrimaryQuery)}
  `

  const GET_SECONDARY_FOOTER_QUERY = `
    footerSecondary: ${footerQuery('Footer Secondary')}
  `

  const navQuery = process.env.DEALER_SITE ? 'Dealer Nav' : 'Main Menu'

  const GET_GLOBAL_NAV_DATA = gql`
    { 
      rangeNav: ${rangeNavQuery()},
      nav: ${headerNavQuery(navQuery)},
      ${GET_FOOTER_QUERY},
      ${GET_SECONDARY_FOOTER_QUERY}
    }
  `

  const result = await client.query({
    query: GET_GLOBAL_NAV_DATA
  });

  // console.log('GlobalNavQueryResult', JSON.stringify(result, null, 2), appProps);

  // const allPages = await globby([
  //   __dirname + 'pages/**/*.js',
  // ]);

  const countryProps = {};
  var domainToTest;
  if (typeof ctx.ctx.req.headers.host === 'undefined') {
    domainToTest = process.env.STATICLY_DEPLOYMENT_DESTINATION_DOMAIN;
  } else {
    domainToTest = ctx.ctx.req.headers.host;
    countryProps.host = ctx.ctx.req.headers.host;
  }
  //domainToTest = ctx.ctx.req.headers.host;

  console.log('______________________ domainToTest _____________________');
  console.log(domainToTest);
  console.log('_________________________________________________________');

  countryProps.countryCode = (domainToTest.indexOf('localhost') !== -1 || domainToTest.indexOf('com-au') !== -1 || domainToTest.indexOf('com.au') !== -1) ? 'AU' : 'NZ';
  countryProps.assignedDomain = !process.env.DEALER_SITE ? (countryProps.countryCode === 'AU' ? 'jayco.com.au' : 'jayco.co.nz') : countryProps.countryCode === 'AU' ? 'AU-dealers' : 'NZ-dealers';

  return {
    ...appProps,
    countryProps: countryProps,
    navProps: refactorHeaderNavV2(result.data),
    footerProps: refactorFooterDataV2(result.data),
  }
}

// export function reportWebVitals(metric) {
//   if (process.browser) {
//     switch (metric.name) {
//       case 'Next.js-hydration':
//         console.log('Next.js-hydration', metric)
//         break;
//       case 'FCP':
//         // handle FCP results
//         console.log('First paint', metric)
//         break
//       case 'LCP':
//         // handle LCP results
//         break
//       case 'CLS':
//         // handle CLS results
//         break
//       case 'FID':
//         // handle FID results
//         break
//       case 'TTFB':
//         // handle TTFB results
//         console.log('Time to first bite', metric)
//         break
//       default:
//         break
//     }
//   }
// }

export default MyApp;
