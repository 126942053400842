import React from 'react'
import propTypes from 'prop-types'
import NextLink from '../../navigation/NextLink';
import { ReactSVG } from 'react-svg';
import styled from 'styled-components';
import chevronRight from '../../../assets/icons/chevron-right-thick.svg';
import { theme } from '../../../themes/default-theme';
import { ButtonBase } from '@material-ui/core';
import calculatorSvg from '../../../assets/icons/calculator.svg';


const Container = styled(ButtonBase)`
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 2px;

    &:hover {
        a:after {
            width: 100%;
        }
    }

    ${theme.breakpoints.down('sm')} {
        font-size: 14px;
    }

    a {
        display: block;
        position: relative;

        &:after {
            transition: width 300ms ease-in-out;
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: -2px;
        }

        ${props => props.colour === 'black' ? `
            color: ${theme.palette.charcoal};
            &:after {
                background-color: ${theme.palette.charcoal};
            }
        `: `
            color: ${theme.palette.white};
            &:after {
                background-color: ${theme.palette.white};
            }
        `}

        ${props => props.colour === 'vividBlue' && `
            color: ${theme.palette.vividBlue};
            &:after {
                background-color: ${theme.palette.vividBlue};
            }
        `}

        text-decoration: none;
    }
`

const Icon = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    svg {
        width: 19px;
        height: auto;
        [fill] {
            ${props => props.colour === 'black' ? `
                fill: ${theme.palette.charcoal};
            `: `
                fill: ${theme.palette.white};
            `}
            ${props => props.colour === 'vividBlue' && `
                fill: ${theme.palette.vividBlue};
            `}

        }
        [stroke] {
            ${props => props.colour === 'black' ? `
                stroke: ${theme.palette.charcoal};
            `: `
                stroke: ${theme.palette.white};
            `}
            ${props => props.colour === 'vividBlue' && `
                stroke: ${theme.palette.vividBlue};
            `}
        }
    }
`

const ChevronIcon = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    position: relative;
    top: -1px;
    svg {
        height: auto;
        width: 7px;

        [stroke] {
            ${props => props.colour === 'black' ? `
                stroke: ${theme.palette.charcoal};
            `: `
                stroke: ${theme.palette.white};
            `}
            ${props => props.colour === 'vividBlue' && `
                stroke: ${theme.palette.vividBlue};
            `}
        }
    }
`

const NoWrap = styled.span `
 white-space: nowrap;
`


const IconLink = ({
    svg,
    link,
    colour,
    onClick,
    showBuildAndPrice
}) => {
    
    const getLastWord = (myString) => {
        if (myString) {
            var n = myString.split(" ");
            return n[n.length - 1];
        } else {
            return myString
        }
    }
    
    const removeLastWord = (myString) => {
        if (myString) {
            return myString.substring(0, myString.lastIndexOf(" "));
        } else {
            return myString
        }
    } 

    return (
        <Container colour={colour}>
            {link.href ?
                (showBuildAndPrice) && (link.href.indexOf('build-price') > -1) ?
                <NextLink href={link.href}>
                    <a target={link.target}>
                        {svg &&
                            <Icon colour={colour}>
                                <ReactSVG src={svg} />
                            </Icon>
                        }
                        <span>
                            {link.title}
                        </span>
                        <ChevronIcon colour={colour}>
                            <ReactSVG src={chevronRight} />
                        </ChevronIcon>
                    </a>
                </NextLink>
                :
                (link.href.indexOf('build-price') == -1) &&
                <NextLink href={link.href}>
                    <a target={link.target}>
                        {svg &&
                            <Icon colour={colour}>
                                <ReactSVG src={svg} />
                            </Icon>
                        }
                        <span>
                            {link.title}
                        </span>
                        <ChevronIcon colour={colour}>
                            <ReactSVG src={chevronRight} />
                        </ChevronIcon>
                    </a>
                </NextLink>
            :
                <a href={'#'} onClick={onClick}>
                    {svg &&
                        <Icon colour={colour}>
                            <ReactSVG src={svg} />
                        </Icon>
                    }
                    <span>
                        {removeLastWord(link.title)}
                    </span>
                    <NoWrap>
                        <span> {getLastWord(link.title)}</span>
                        <ChevronIcon colour={colour}>
                            <ReactSVG src={chevronRight} />
                        </ChevronIcon>
                    </NoWrap>
                </a>
            }
        </Container>
    )
}
    
IconLink.propTypes = {
    colour: propTypes.string,
    showBuildAndPrice: propTypes.bool
}
    
IconLink.defaultProps = {
    svg: null,
    colour: 'black', // black or white
    onClick: () => {},
    showBuildAndPrice: false
}
    
export default IconLink