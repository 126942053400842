import React, { useState, useEffect } from 'react'
import Link from '../NextLink';
import RespImg from '../../ui/RespImg';
import NavClose from '../../../assets/icons/new_nav_close.svg';
import { MainNavContentInner, MainNavFirst, MainNavContentItem, NavItem, NavContent, MainNavSecond, DesktopNavClose } from './MainNav'
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';

const MainNavContentItemInner = styled.div`
${props => props.display == false && `
    display: none;
`
    }

`

const ExploreNav = (props) => {
    const {
        item,
        display,
        desktopView,
        mobileView,
        handleNavClose,
        handleMobileExplore,
        refactorURL,
        mobileExploreItems,
        onDealerData
    } = props

    const [dealerComponentData, setDealerComponentData] = useState(false);
    const [subNav, setSubNav] = useState(item);
    const [exploreData, setExploreData] = useState(null);


    useEffect(() => {

        try {
            const component = document.getElementById('staticly-dynamics-component-ExploreNav');
            // const properties = component ? component.getAttribute('data-component-properties') : null;
            // const jsonData  = properties ? JSON.parse(properties) : null;
            const jsonData = window.staticly.data && window.staticly.data.ExploreNav ? window.staticly.data.ExploreNav : null

            if (jsonData) {
                // Refactor here
                const data = jsonData.data;

                if (!data) {
                    component && component.remove();
                    return
                }

                const arr = Object.values(data).filter(item => item.menuItems.length !== 0).map(item => {
                    return {
                        ...item,
                        assignedDomains: ['jayco.com.au', 'jayco.co.nz', 'AU-dealers', 'NZ-dealers'],
                    }
                });

                const newData = arr.map((item, index) => {
                    return {
                        ...item,
                        title: item.name,
                        links: item.menuItems && item.menuItems.items && item.menuItems.items.map(childItem => {
                            return {
                                ...childItem.item,
                                assignedDomains: ['jayco.com.au', 'jayco.co.nz', 'AU-dealers', 'NZ-dealers'],
                                classes: []
                            }
                        }),
                        classes: [item.classes],
                        href: item.slug
                    }
                })

                onDealerData({ items: newData });
                setExploreData({ items: newData });
                setSubNav({ items: newData });
                setDealerComponentData(true);
                component && component.remove();
            }
        } catch {
            // do nothing
        }
    }, [])

    const exploreString = `{{#staticlyDynamic data from="template.menus" to="data" id="ExploreNav" component="src/components/navigation/NewMainNav/ExploreNav.js"}}{{/staticlyDynamic}}`

    console.log('subNav', { subNav, exploreData });

    if (process.env.DEALER_SITE && !dealerComponentData) {
        return (
            <div dangerouslySetInnerHTML={{ __html: exploreString }}>
            </div>
        )
    } else {
        return (
            <MainNavContentInner normal="true" flex="true" display={display}>
                <MainNavFirst>
                    {(subNav && subNav.items) && subNav.items.map((item, index) => {
                        return (
                            <MainNavContentItem key={item.id ? item.id : index} normal="true">
                                {desktopView &&
                                    item.title &&
                                    <NavItem>
                                        <Link href={'/'}>
                                            <a  onClick={(e) => {
                                                    e.preventDefault()
                                                }}>
                                                {item.title}
                                            </a>
                                        </Link>
                                    </NavItem>
                                }
                                <MainNavContentItemInner display={((mobileExploreItems == index) || (desktopView)) ? true : false}>
                                    {mobileView &&
                                        <MainNavContentItem list="true" normal="true">
                                            <NavItem back="true" noborder="true">
                                                <Link>
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            handleMobileExplore(null, 100);
                                                        }}>
                                                        Back
                                                    </a>
                                                </Link>
                                            </NavItem>
                                        </MainNavContentItem>
                                    }
                                    {item.links && item.links.length > 0 &&
                                        <MainNavContentItem normal="true">
                                            <NavContent>
                                                {item.links.map((item, index) => {
                                                    return (
                                                        <NavItem key={item.id ? item.id : index}>
                                                            <Link href={item.href && refactorURL(item.href)} target={item.target && item.target}>
                                                                <a>
                                                                    {item.title}
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                    )
                                                })
                                                }
                                            </NavContent>
                                        </MainNavContentItem>
                                    }
                                </MainNavContentItemInner>
                            </MainNavContentItem>
                        )
                    })

                    }
                </MainNavFirst>
                {desktopView &&
                    <MainNavSecond>
                        {item.image &&
                            <RespImg
                                {...item.image}
                                visible={display}
                            />
                        }
                    </MainNavSecond>
                }
                {desktopView &&
                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                }
            </MainNavContentInner>
        )
    }
}

ExploreNav.propTypes = {
}

ExploreNav.defaultProps = {
}

export default ExploreNav